import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import SelectionComponent from './SelectionComponent';
import { FormControl, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import { Estados } from './ListaDeEstados.js'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { NumericFormat } from 'react-number-format';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import BoltIcon from '@mui/icons-material/Bolt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Skeleton from '@mui/material/Skeleton';

function FormSection({ formData, setFormData, onSubmit }) {
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [tarifas, setTarifas] = useState([]);
  const [BackdropIsOpen, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [collapseOpen, setCollapseOpen] = useState(() => {
    const savedState = getItemWithExpiry('collapseState');
    return savedState !== null ? savedState : true; // Sempre inicializa como aberto
  });
  const [gastoMensalRange, setGastoMensalRange] = useState({ min: 100, max: 800 });
  const [estadoError, setEstadoError] = useState(false);
  const [cidadeError, setCidadeError] = useState(false);
  const [tarifaError, setTarifaError] = useState(false);
  const [loadingCitiesAndFees, setLoadingCitiesAndFees] = useState(false);

  useEffect(() => {
    setEstados(Estados);//load states list
  }, []);

  useEffect(() => {
    function handleResize() {
      const isMob = window.innerWidth <= 768;
      setCollapseOpen(prevState => {
        const newCollapseOpen = isMob ? prevState : true;
        setItemWithExpiry('collapseState', newCollapseOpen, 24 * 60 * 60 * 1000); // Define a expiração para 24 horas
        return newCollapseOpen;
      });
    }

    // Adiciona um event listener para escutar mudanças de tamanho da janela
    window.addEventListener('resize', handleResize);

    // Remove o event listener quando o componente é desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => { //
    if (formData.estado && formData.estado !== '') {
      BuildCitiesAndFees(formData.estado);
    }
    else {
      setFormData(prevData => ({ //reset components in case of Empty state
        ...prevData,
        temEnergia: true,
        municipio: '',
        tarifaComImposto: 0,
        tipo: "Residencial",
        distribuidora: '',
        gastoMensal: 300,
      }));
    }
  }, [formData.estado, setFormData]);

  useEffect(() => {
    if (tarifas.length > 0) {
      setFormData(prevData => ({
        ...prevData,
        distribuidora: tarifas[0].distribuidora,
        tarifaComImposto: tarifas[0].tarifaConvencional,
      }));
    }
  }, [tarifas, setFormData]);


  useEffect(() => {
    if (formData.tarifaComImposto > 0) {
      setFormData((prevData) => ({
        ...prevData,
        consumoMensal: prevData.gastoMensal / formData.tarifaComImposto,
      }));
    }
  }, [formData.tarifaComImposto, setFormData]);



  const handleSliderChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      gastoMensal: newValue,
      consumoMensal: newValue / formData.tarifaComImposto,
    }));
  };


  const handleCurrencyChange = (value) => {
    const { floatValue } = value;
    setFormData((prevData) => ({
      ...prevData,
      tarifaComImposto: floatValue,
    }));
    if (floatValue > 0) {
      setTarifaError(false);
      updateConsumoMensal(formData.gastoMensal, floatValue);
    }
  };

  const BuildCitiesAndFees = async (uf) => {
    try {
      setLoadingCitiesAndFees(true);
      const storedData = getItemWithExpiry(`citiesAndFees_${uf}`);
      if (storedData && storedData.cities.length > 0) {
        setCidades(storedData.cities);
        setTarifas(storedData.fees);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API_URL}/SimuladorFV`
      const response = await fetch(`${apiUrl}/GetCitiesAndFees?uf=${uf}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        console.error('Erro ao buscar cidades');
        setCidades([]);
        setTarifas([]);
      }
      else {
        const data = await response.json();
        console.log(data)
        setCidades(data.cities);
        setTarifas(data.fees);
        setItemWithExpiry(`citiesAndFees_${uf}`, data, 24 * 60 * 60 * 1000);   //24 HORAS
      }
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
    finally {
      setLoadingCitiesAndFees(false);
    }
  }

  const handleSelect = (option) => {
    const currentFee = tarifas.find(x => x.distribuidora === formData.distribuidora);
    setFormData((prevData) => ({
      ...prevData,
      tipo: option,
      tarifaComImposto: currentFee ? currentFee.tarifaConvencional : '',
    }));

    // Atualizar valores de range com base no tipo de local
    if (option === 'Residencial') {
      setGastoMensalRange({ min: 100, max: 800 });
    } else if (option === 'Empresarial') {
      setGastoMensalRange({ min: 1000, max: 100000 });
    }
  };

  const handleExpandScale = () => {
    const incremento = formData.tipo === "Residencial" ? 1000 : 50000;
    setGastoMensalRange(prevRange => ({
      ...prevRange,
      min: prevRange.min,
      max: prevRange.max + incremento,
    }));

    if (formData.gastoMensal <= gastoMensalRange.min || formData.gastoMensal > gastoMensalRange.max) {
      setFormData(prevData => ({
        ...prevData,
        gastoMensal: gastoMensalRange.min,
        consumoMensal: gastoMensalRange.max * formData.tarifaComImposto,
      }));
    }
  };

  const handleSwitchChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      temEnergia: event.target.checked,
    }));
  };

  const handleCollapseToggle = () => {
    setCollapseOpen((prev) => {
      const newCollapseOpen = !prev;
      const expiryTime = 5 * 1000; // 5 segundos
      setItemWithExpiry('collapseState', newCollapseOpen, expiryTime);
      return newCollapseOpen;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.estado || formData.estado.length === 0) {
      setEstadoError(true);
      return;
    }
    if (!formData.municipio || formData.municipio.length === 0) {
      setCidadeError(true);
      return;
    }

    if (!formData.tarifaComImposto || formData.tarifaComImposto === 0) {
      setTarifaError(true);
      return;
    }

    if (!formData.estado || !formData.municipio) {
      return;
    }

    setLoading(true);
    try {
      await onSubmit(formData); // Executa o envio dos dados
      setLoading(false); // Finaliza o loading após a conclusão do envio
      if (isMobile) {
        setCollapseOpen(false);
        const expiryTime = 5 * 1000; // 5 segundos
        setItemWithExpiry('collapseState', false, expiryTime);
      }
    } catch (error) {
      console.error('Erro ao submeter formulário:', error);
      setLoading(false); // Finaliza o loading em caso de erro também
    }
  };

  const enableLocation = async (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        await setAddressFromCoordinates(latitude, longitude);
      }, handleGeolocationError);
    }
  };

  const handleGeolocationError = (error) => {
    console.error('Erro ao obter geolocalização:', error);
  };

  const setAddressFromCoordinates = async (latitude, longitude) => {
    // Use a geocoding API (like Nominatim) to convert coordinates to a postal code (CEP)
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
      if (!response.ok) {
        console.error('Não foi possível obter os dados do endereço.');
      }
      else {
        const data = await response.json();
        console.log('Address data:', data);
        const estado = estados.find((estado) => estado.nome === data.address.state);
        BuildCitiesAndFees(estado.uf);
        setFormData((prevData) => ({
          ...prevData,
          estado: estado.uf,
          municipio: data.address.city ? data.address.city : data.address.town,
          distribuidora: tarifas.length > 0 ? tarifas[0].distribuidora : '',
          tarifaComImposto: tarifas.length > 0 ? tarifas[0].tarifaConvencional : ''
        }));
      }
    } catch (error) {
      console.error('Erro ao buscar dados do endereço:', error);
    }
  };

  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return 'R$ 0,00';
  };

  const updateConsumoMensal = (gastoMensal, tarifaComImposto) => {
    const consumo = gastoMensal / tarifaComImposto;
    setFormData((prevData) => ({
      ...prevData,
      consumoMensal: consumo,
    }));
  };

  return (
    <Box
      sx={{
        border: isMobile ? '1px solid rgba(0, 0, 0, 0.1)' : 'none', // Borda preta com 50% de transparência
        //borderColor: 'secondary.main', // Cor da borda (utilizando a paleta de cores do tema)
        m: '2px', // m: 2 Margem de 16px (equivalente a 2*8px)
        borderRadius: 2, // Raio da borda de 16px (equivalente a 2*8px)
        justifyContent: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', color: 'primary.main', borderRadius: "4px" }}>
        <Typography variant="h6" component="h6">
          Parâmetros do Simulador
        </Typography>
        <IconButton
          sx={{ display: isMobile ? 'flex' : 'none', ml: 'auto', justifyContent: 'space-between' }}
          aria-label={collapseOpen ? 'Minimizar' : 'Expandir'}
          onClick={handleCollapseToggle}
          size="medium"
        >
          {collapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={collapseOpen} sx={{ justifyContent: 'center' }}>
        <form onSubmit={handleSubmit}>
          <Typography gutterBottom>
            Qual o tipo de local?
          </Typography>
          <SelectionComponent onSelect={handleSelect} />
          <Box sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            margin: 1,
            marginBottom: 1,
            gap: 2
          }}>
            <Grid container spacing={0}>
              <Grid item xs>
                <Autocomplete
                  id="estado"
                  options={estados}
                  getOptionLabel={(option) => option.nome}
                  value={estados.find((estado) => estado.uf === formData.estado) || null}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.uf.length > 0) {
                      setEstadoError(false);
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      estado: newValue ? newValue.uf : '',
                      municipio: '',
                      tarifas: [],
                      distribuidora: '',
                    }));

                  }}
                  renderInput={(params) => <TextField {...params} label="Estado" error={estadoError} />}
                  sx={{ width: '92%', margin: 1, marginBottom: 1 }}
                  size='small'
                  isOptionEqualToValue={(option, value) => option.uf === value.uf}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Obter localização automática">
                  <IconButton onClick={enableLocation} aria-label="enable-Location">
                    <LocationOnIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Autocomplete
              options={cidades}
              getOptionLabel={(option) => option.municipio}
              value={formData.municipio ? { municipio: formData.municipio } : null}
              onChange={(event, newValue) => {
                if (newValue && newValue.municipio.length > 0) {
                  setCidadeError(false);
                }
                setFormData((prevData) => ({
                  ...prevData,
                  municipio: newValue ? newValue.municipio : ''
                }));
              }}
              noOptionsText="Nenhum município encontrado"
              size='small'
              sx={{ width: '82%', margin: 1, marginBottom: 1 }}
              renderInput={(params) => <TextField {...params} label="Município" error={cidadeError} />}
              isOptionEqualToValue={(option, value) => option.municipio === value.municipio}
              disabled={formData.estado === null || formData.estado === ''}
            />
            {formData.tipo === 'Residencial' && (
              <FormControl sx={{ width: '82%', margin: 1, marginBottom: 1 }} size="small">
                <InputLabel id="distribuidora-label">Distribuidora</InputLabel>
                {loadingCitiesAndFees ? (
                  <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                  <>                   
                    <Select
                      name="Distribuidora"
                      labelId="distribuidora-label"
                      label="Distribuidora"
                      variant="outlined"
                      size='small'
                      value={(formData.distribuidora || '')}
                      onChange={(event) => {
                        const { value } = event.target;
                        setFormData((prevData) => ({
                          ...prevData,
                          distribuidora: value,
                          tarifaComImposto: tarifas.find(t => t.distribuidora === value)?.tarifaConvencional || 0
                        }));

                      }}
                    >
                      {tarifas.map((tarifa) => (
                        <MenuItem key={tarifa.distribuidora} value={tarifa.distribuidora}>
                          {tarifa.distribuidora}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            )}
            {loadingCitiesAndFees ? (
              <Skeleton variant="rectangular" width="82%" height={40} />
            ) : (
              <NumericFormat
                value={formData.tarifaComImposto}
                onValueChange={handleCurrencyChange}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                label="Tarifa com imposto"
                variant="outlined"
                size="small"
                sx={{ width: '82%', margin: 1, marginBottom: 1 }}
                inputProps={{ inputMode: 'numeric' }}
                customInput={TextField}
                error={tarifaError}
              />
            )}
          </Box>

          <Box sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '96%',
            margin: 1,
            marginBottom: 0
          }}>
            <Grid container spacing={0}>
              <Grid item xs={7} sm={7}>
                <Typography gutterBottom>
                  O local possui rede elétrica instalada?
                </Typography>
              </Grid>
              <Grid item xs={5} sm={5}>
                <FormControlLabel
                  control={<Switch defaultChecked onChange={handleSwitchChange} />}
                  label={formData.temEnergia ? "Sim" : "Não"}
                  sx={{ margin: 1, marginBottom: 0 }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '96%',
            margin: 1,
            marginBottom: 0
          }}>
            <Typography id="gastoMensalLabel" gutterBottom>
              Gasto mensal:
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <BoltIcon />
              </Grid>
              <Grid item xs>
                <Slider
                  name='gastoMensal'
                  min={gastoMensalRange.min}
                  max={gastoMensalRange.max}
                  onChange={handleSliderChange}
                  aria-labelledby="gastoMensalLabel"
                />
              </Grid>
              <Grid item>
                <Tooltip title="Expandir escala">
                  <IconButton onClick={handleExpandScale} aria-label="Expandir escala">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="center" marginBottom={0}>
            <ConsumptionPaper variant="elevation" elevation={3}>
              <Typography variant="h6" component="h6">{formatCurrency(formData.gastoMensal)}</Typography>
              <Typography variant="h6" component="h6">{formatEnergy(formData.consumoMensal)}</Typography>
            </ConsumptionPaper>
          </Box>

          <Box display="flex" justifyContent="left">
            <Button variant="contained" sx={{ width: '100%', margin: 1, height: '40px' }} onClick={handleSubmit} startIcon={<PlayArrowIcon />}>Simular</Button>
          </Box>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={BackdropIsOpen}
            onClick={handleSubmit}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

        </form>
      </Collapse>
    </Box>
  );
}

const ConsumptionPaper = styled(Paper)(({ theme }) => ({
  width: '60%',
  margin: 1,
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark' ? 'default' : 'LemonChiffon ',
  padding: theme.spacing(2),
  ...theme.typography.body1,
  textAlign: 'center',
}));

function formatEnergy(value, unit = 'KWh') {
  var newUnit = '';
  var adjustedValue = value;

  if (value <= 50000) {
    return `${value.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} ${unit}`;
  } else {

    if (unit === 'KWh') {
      adjustedValue = value / 1000;
      newUnit = 'MWh'
    }
  }

  return `${adjustedValue.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} ${newUnit}`;
}

function setItemWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` é um objeto que contém o valor original e a data de expiração
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // Se o item não existe, retorna null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compara a data de expiração com a data atual
  if (now.getTime() > item.expiry) {
    // Se o item expirou, remove-o do `localStorage` e retorna null
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export default FormSection;
